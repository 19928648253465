<template>
  <v-container>
    <verification-alert></verification-alert>

    <alert :text="alertText" :type="alertType"></alert>

    <v-form v-if="$auth.isVerified($store.getters.wallet)" ref="investmentForm" v-model="formValid">
      <p v-if="contract.name" class="text-h6">
        {{ contract.name }} ({{ contract.symbol }})
      </p>

      <p v-if="!flipped" class="mb-6">
        Are you sure you want to flip this contract?
      </p>

      <p v-if="flipped" class="mb-6">
        This contract has been flipped.
      </p>

      <v-btn block rounded color="primary" class="mb-4" :loading="flipping" :disabled="flipped" @click="flip">
        Flip
        <v-icon v-if="flipped" right>
          {{ icons.mdiCheck }}
        </v-icon>
      </v-btn>

      <p class="text-center text-caption mb-0">
        The <i>flip</i> step can take a few minutes to be confirmed.
      </p>
    </v-form>
  </v-container>
</template>

<script>
import { mdiCurrencyUsd, mdiCheck } from '@mdi/js'
import VerificationAlert from '@/components/VerificationAlert.vue'
import Alert from '@/components/Alert.vue'

export default {
  components: { VerificationAlert, Alert },
  setup() {
    return {
      icons: {
        mdiCurrencyUsd,
        mdiCheck,
      },
    }
  },
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  data() {
    return {
      contract: {},
      formValid: false,
      flipping: false,
      flipped: false,
      alertText: undefined,
      alertType: undefined,
    }
  },
  watch: {
    'project.address': function(address, oldAddress) {
      if (address) {
        this.$store.commit('setContract', { address: this.project.address })
        this.loadContract()
      }
    },
    '$store.state.chainId': function(chainId, oldChainId) {
      if (oldChainId && this.project.address) {
        this.$store.commit('setContract', { address: this.project.address })
        this.loadContract()
      }
    },
  },
  mounted() {
    if (this.$auth.isVerified(this.$store.getters.wallet) && this.project.address) {
      this.loadContract()
    }
  },
  methods: {
    loadContract() {
      this.$store.dispatch('loadContract', this.project.address).then((contract) => {
        this.contract = contract
        this.alertText = undefined
        this.alertType = undefined
      }).catch(() => {
        this.contract = {}
        this.alertText = 'Wrong network selected. Please switch to ' + this.$store.getters.platformName(this.project.chain_id) + '.'
        this.alertType = 'warning'
      })
    },
    flip() {
      this.flipping = true
      var wallet = this.$store.getters.wallet
      var instance = new this.$web3.eth.Contract(this.$PartnershipFlipABI, this.project.address)

      instance.methods.flip().estimateGas({ from: wallet }).then((gasLimit) => {
        this.$web3.eth.getGasPrice().then((gasPrice) => {
          instance.methods.flip().send({ from: wallet, gasLimit, gasPrice })
            .on('receipt', () => {
              this.flipping = false
              this.flipped = true
              this.alertText = 'Contract flipped successfully'
              this.alertType = 'success'
              this.$root.$emit('Flip')
              this.$store.commit('investmentsClear')
            })
            .on('error', (error) => {
              this.showError(error)
            })
        }).catch((error) => {
          this.showError(error)
        })
      }).catch((error) => {
        this.showError(error)
      })
    },
    showError(error) {
      this.flipping = false
      var match = error.message.match(/"message": "(.*)"/)
      this.alertText = match ? match[1] : error.message
      this.alertType = 'error'
    },
  },
}
</script>

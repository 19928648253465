<template>
  <v-row justify="center">
    <v-col sm="10" md="8" lg="6" align="stretch">
      <h1 class="ps-3 text-h5">Flip Project Ownership</h1>
      <flipping-form :project="project"></flipping-form>
    </v-col>
  </v-row>
</template>

<script>
import FlippingForm from './FlippingForm.vue'

export default {
  name: 'Flip',
  components: {
    FlippingForm,
  },
  data() {
    return {
      project: {},
    }
  },
  mounted() {
    this.project = this.$store.getters.getProject(this.$route.params.slug)
  },
}
</script>
